<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">PROMOCODES</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
      <div class="flex justify-space-between mv-10">
        <div class="box left center">
          <el-input placeholder="Search"
                    size="small"
                    ref="search"
                    v-model="search"
                    style="width: 200px;"
                    @input="handleSearchInput"
                    class="box center"></el-input>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          background
          :pager-count="5"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[5, 10, 20, 50]"
          :page-size="pageSize"
          :total="filtered.length">
        </el-pagination>
        <div class="box right center">
          <el-button @click="addRow()" size="small">
              Add Promocode
          </el-button>
        </div>
    </div>
    <div :style="{width: tableWidth + 'px'}">
      <el-table
        :data="paginated"
        :max-height="tableHeight"
        style="width: 100%">
        <el-table-column
          width="120"
          label="Code">
              <template #default="scope">
                <el-input size="small"
                  style="text-align:center"
                  v-model="scope.row.code"></el-input>
              </template>
        </el-table-column>
        <el-table-column
          width="250"
          label="Description">
              <template #default="scope">
                <el-input size="small"
                  type="textarea"
                  rows="1"
                  style="text-align:center"
                  v-model="scope.row.description"></el-input>
              </template>
        </el-table-column>
        <el-table-column
          label="Discount, %">
              <template #default="scope">
                <el-input-number size="small"
                  :controls="false"
                  style="text-align:center"
                  v-model="scope.row.discount"></el-input-number>
              </template>
        </el-table-column>
        <el-table-column
          label="Quantity">
              <template #default="scope">
                <el-input-number size="small"
                  :controls="false"
                  style="text-align:center"
                  v-model="scope.row.quantity"></el-input-number>
              </template>
        </el-table-column>
        <el-table-column
          width="65"
          align="center"
          prop="used"
          label="Used">
        </el-table-column>
        <el-table-column
          label="Start Date">
              <template #default="scope">
                <el-date-picker
                  size="small"
                  v-model="scope.row.startDate"
                  type="date"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </template>
        </el-table-column>
        <el-table-column
          label="End Date">
              <template #default="scope">
                <el-date-picker
                  size="small"
                  v-model="scope.row.endDate"
                  type="date"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </template>
        </el-table-column>
        <el-table-column
          label="Is Groupe">
              <template #default="scope">
                <el-switch v-model="scope.row.isGroup"></el-switch>
              </template>
        </el-table-column>
        <el-table-column
          label="Is Personal">
              <template #default="scope">
                <el-switch v-model="scope.row.isPersonal"></el-switch>
              </template>
        </el-table-column>
        <el-table-column
          width="250"
          label="Customer">
          <template #default="scope">
            <el-select
              size="small"
              v-model="scope.row.customerId"
              filterable
              remote
              reserve-keyword
              placeholder="3 symbols"
              :remote-method="remoteMethod">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="Is Active">
              <template #default="scope">
                <el-switch v-model="scope.row.isActive"></el-switch>
              </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">

              <div v-if="scope.$index >= promocodes.length">
                  <el-button
                  @click.native.prevent="addPromo(scope.row)" type="text" size="small">
                      Create
                  </el-button>
              </div>
              <div class="flex gaps" v-else>
                  <el-button @click.native.prevent="saveRow(scope.row)" type="text" size="small">
                      Save
                  </el-button>
                  <el-button @click.native.prevent="deleteRow(scope.row, scope.$index)" type="text" size="small">
                      Delete
                  </el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Promocode',

  data () {
    return {
      dataPromos: [],
      options: [],
      pickerOptions: {
        shortcuts: [{
          text: 'today',
          onClick (picker) {
            const date = new Date()
            let d = date.getTime()
            date.setTime(d - (d % (3600 * 1000 * 24)))
            picker.$emit('pick', date)
          }
        }, {
          text: '1 day',
          onClick (picker) {
            const date = new Date()
            let d = date.getTime()
            date.setTime(d - (d % (3600 * 1000 * 24)) + (2 * (3600 * 1000 * 24)))
            picker.$emit('pick', date)
          }
        }, {
          text: '1 week',
          onClick (picker) {
            const date = new Date()
            let d = date.getTime()
            date.setTime(d - (d % (3600 * 1000 * 24)) + (8 * (3600 * 1000 * 24)))
            picker.$emit('pick', date)
          }
        }, {
          text: '1 mounth',
          onClick (picker) {
            const date = new Date()
            let d = date.getTime()
            date.setTime(d - (d % (3600 * 1000 * 24)) + (32 * (3600 * 1000 * 24)))
            picker.$emit('pick', date)
          }
        }, {
          text: '3 mounth',
          onClick (picker) {
            const date = new Date()
            let d = date.getTime()
            date.setTime(d - (d % (3600 * 1000 * 24)) + (93 * (3600 * 1000 * 24)))
            picker.$emit('pick', date)
          }
        }, {
          text: '6 mounth',
          onClick (picker) {
            const date = new Date()
            let d = date.getTime()
            date.setTime(d - (d % (3600 * 1000 * 24)) + (184 * (3600 * 1000 * 24)))
            picker.$emit('pick', date)
          }
        }, {
          text: '1 year',
          onClick (picker) {
            const date = new Date()
            let d = date.getTime()
            date.setTime(d - (d % (3600 * 1000 * 24)) + (366 * (3600 * 1000 * 24)))
            picker.$emit('pick', date)
          }
        }]
      },
      search: '',
      currentPage: 1,
      pageSize: 10
    }
  },

  computed: {
    ...mapState({
      promocodes: state => state.promocode.promocodes,
      innerHeight: state => state.nav.innerHeight,
      innerWidth: state => state.nav.innerWidth
    }),
    ...mapGetters(['customersForSelect', 'getMemberFullNameById']),
    tableHeight () {
      return this.innerHeight - 181
    },
    tableWidth () {
      return this.innerWidth - 30
    },
    clearOption () {
      let personalPromosArray = this.promocodes.filter(p => p.isPersonal).map(p => p.customerId)
      return this.customersForSelect.filter(c => personalPromosArray.includes(c.value))
    },
    filtered () {
      return this.dataPromos.filter(item => {
        return this.search.length < 2 ||
       (item.hasOwnProperty('code') && item.code.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('description') && item.description.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('isPersonal') &&
        item.isPersonal &&
        item.hasOwnProperty('customerId') &&
        this.getMemberFullNameById[item.customerId].toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      })
    },
    paginated () {
      return this.filtered.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },

  methods: {
    handleSizeChange (val) {
      this.pageSize = val
      localStorage.setItem('pageSize', val)
    },
    handleSearchInput () {},
    GetNewPromo (length) {
      const line = 'abcdefghijklmnopqrstuvwxyz0123456789'
      let password = ''
      while (password.length < length) {
        password += line[Math.floor(Math.random() * line.length)]
      }
      return password
    },
    remoteMethod (query) {
      if (query !== '' && query.length > 2) {
        this.options = this.customersForSelect.filter(item => {
          return item.label.toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        })
      } else {
        this.options = this.clearOption
      }
    },
    deleteRow (row, index) {
      this.$store.dispatch('removePromo', row._id)
        .then(() => {
          this.dataPromos.splice(index, 1)
        })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Promocode removed successfully',
            type: 'success'
          })
        })
    },
    saveRow (row) {
      let content = Object.assign({}, row)
      if (!content.isPersonal) {
        content.customerId = ''
      }
      this.$store.dispatch('editPromo', {
        id: row.uid,
        content: content
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Promocode edited successfully',
            type: 'success'
          })
        })
    },
    addPromo (payload) {
      this.$store.dispatch('addPromo', payload)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Promo created successfully',
            type: 'success'
          })
        })
    },
    addRow () {
      let newRow = {
        code: this.GetNewPromo(8),
        description: '',
        discount: 20,
        quantity: -1,
        used: 0,
        startDate: new Date(new Date().toISOString().slice(0, 10)),
        endDate: new Date('2101-01-01'),
        isGroup: false,
        isActive: true,
        isPersonal: false,
        customerId: ''
      }
      this.dataPromos = [...this.dataPromos, newRow]
    }
  },
  created () {
    if (localStorage.getItem('pageSize') !== null) {
      this.pageSize = Number(localStorage.getItem('pageSize'))
    }
  },
  mounted () {
    this.dataPromos = this.promocodes.map((x) => Object.assign({}, x))
    this.options = this.clearOption
  }
}
</script>

<style lang="scss">
</style>
